<template>
  <div
    class="mats-listing__wrapper"
    style="margin-top: -8px;width:100%"
    @click="removeLists"
  >
    <div class="filtering">
      <div class="search-toggle">

        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: toggleSarch === 'CustomerName' }"
            @click="showCustomerName(), toggleSarch = 'CustomerName'"
          >
            {{ $t('CustomerName') }}
          </p>
          <p
            :class="{ active2: toggleSarch === 'OrderNumber' }"
            @click="showOrderNumber(), toggleSarch = 'OrderNumber'"
          >
            {{ $t('OrderNumber') }}
          </p>
          <p
            :class="{ active2: toggleSarch === 'ShippingNotes' }"
            @click="showShippingNotes(), toggleSarch = 'ShippingNotes'"
          >
            {{ $t('ShippingNotes') }}
          </p>
        </div>
      </div>
      <div v-if="toggleSarch === 'CustomerName'">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchCN == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchCN != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchCN = ''"
            />
            <input
              v-model="searchCN"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('CustomerName')"
              :style="getFilteredOrdersbyCN.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged1"
              @keydown.down="onArrowDown1"
              @keydown.up="onArrowUp1"
            >
          </div>
          <div
            v-if="getFilteredOrdersbyCN.length > 0 && noSearchTwo"
            ref="scrollContainer"
            class="dropdrop"
            style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;
            "
            :style="getFilteredOrdersbyCN.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilteredOrdersbyCN"
              ref="options2"
              :key="index"
              :class="{ 'is-active': index === arrowCounter }"
              style="cursor: pointer"
              class="search-item"
              @click="searchByCn(result ); noSearchTwo = false"
            >
              <p style="margin: 0">
                {{ result.nameToDisplay }}
              </p>
            </div>
            <div
              v-if="getFilteredOrdersbyCN.length == 0"
              class="search-item"
            >
              <p>{{ $t('NoResult') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="toggleSarch === 'OrderNumber'">
        <div class="black">
          <b-icon-search
            v-if="searchOrder == ''"
            class="search1"
            style=" position: absolute; left: 5px; top: 13px"
          />
          <b-icon-x-circle
            v-else-if="searchOrder != '' && !noSearch"
            class="search1"
            style="position: absolute; margin-top: -6px; right: 20px; top: 17px; cursor: pointer"
            @click="searchOrder = ''"
          />
          <input
            v-model="searchOrder"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t('OrderNumber')"
            :style="getFilteredOrdersbyON.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : ''"
            @keyup="inputChanged"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
          >
        </div>
        <div
          v-if="getFilteredOrdersbyON.length > 0 && noSearch"
          ref="scrollContainer"
          class="dropdrop"
          :style="getFilteredOrdersbyON.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getFilteredOrdersbyON"
            :key="index"
            ref="options"
            style="cursor: pointer"
            class="search-item"
            :class="{ 'is-active': index === arrowCounter }"
            @click="searchByOn(result); noSearch = false"
          >
            <p style="margin: 0">
              {{ result }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="toggleSarch === 'ShippingNotes'">
        <div class="black">
          <b-icon-search
            v-if="searchShip == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 13px"
          />
          <b-icon-x-circle
            v-else-if="searchShip != '' && !noSearchThree"
            class="search1"
            style="position: absolute; margin-top: -6px; right: 20px; top: 17px; cursor: pointer"
            @click="searchShip = ''"
          />
          <input
            v-model="searchShip"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t('ShippingNotes')"
            :style="getFilteredOrdersByShipment.length > 0 && noSearchThree ? 'border-radius: 8px 8px 0px 0px' : ''"
            @keyup="inputChanged3"
            @keydown.down="onArrowDown3"
            @keydown.up="onArrowUp3"
          >
        </div>
        <div
          v-if="getFilteredOrdersByShipment.length > 0 && noSearchThree"
          ref="scrollContainer2"
          class="dropdrop"
          style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              z-index: 423 !important;
              width: 229px;
              box-shadow: none;
            "
          :style="getFilteredOrdersByShipment.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getFilteredOrdersByShipment"
            ref="options2"
            :key="index"
            :class="{ 'is-active': index === arrowCounter3 }"
            style="cursor: pointer"
            class="search-item"
            @click="searchByShip(result); noSearchThree = false"
          >
            <p style="margin: 0">
              {{ result.notes }}
            </p>
          </div>
          <div
            v-if="getFilteredOrdersByShipment.length == 0"
            class="search-item"
          >
            <p>{{ $t('NoResult') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px">
      <button
        v-b-modal.created-order
        class="button"
      >
        {{ $t('ChangeDate') }}
      </button>
    </div>
    <div style="width: 100%;padding-top: 16px; margin-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th />
            <th>
              {{ $t('No') }}
            </th>
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
            <th>
              {{ $t('FullName') }}
            </th>
            <th>
              {{ $t('OrderState') }}
            </th>

            <th>
              {{ $t('Created') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getAdminOrders.length > 0">
          <tr
            v-for="(order,index) in getAdminOrders"
            :key="index"
          >
            <td>
              <input
                v-model="selectedClients"
                type="checkbox"
                :value="order"
                @click.stop="singleCheckbox(order)"
              >
            </td>
            <td>
              {{ getTotalItemsForOrders - (page - 1) * pageSize - index }}.
            </td>
            <td>
              {{ order.orderNumber }}
            </td>
            <td v-if="order.orderType == 'InStore'">
              {{ $t("InStore") }}
            </td>
            <td v-else-if="order.orderType == 'WholeSale'">
              {{ $t("WholeSale") }}
            </td>
            <td v-else>
              {{ order.orderType }}
            </td>
            <td>
              {{ order.clientName }}
            </td>
            <td>
              {{ $t(order.orderState) }}
            </td>
            <td>
              {{ convertUTCDateToLoacalDate(order.created) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="6"
            >
              {{ $t('NoOrderWasFound') }}!
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsForOrders > 15"
          v-model="page"
          :total-rows="getTotalItemsForOrders"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
    <EditCreatedDate
      :order-items="selectedClients"
      @changeDate="changeOrderDate"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import EditCreatedDate from './modals/EditCreatedDate.vue'

export default {
  components: {
    EditCreatedDate,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  data() {
    return {
      allUsersPagination: [],
      allClients: [],
      selectedClients: [],
      isAllChecked: false,
      noSearchTwo: false,
      clientId: null,
      toggleSarch: 'CustomerName',
      noSearchThree: false,
      searchShip: '',
      show: true,
      searchCN: '',
      searchOrder: '',
      orderNAme: null,
      orderId: null,
      noSearch: false,
      rows: {
        items: [],
      },
      page: 1,
      pageSize: 15,
      arrowCounter: 0,
      enterPressed: false,
      pageSize2: 20,
    }
  },
  computed: {
    ...mapGetters(['getLanguage', 'getCurrentPageForOrders', 'getCreateOrdersPDF', 'getLoggedInUser', 'getOrderTypes', 'getCreatedOrders', 'getTotalItemsForOrderN', 'getCurrentPageForCreatedOrders', 'getIsLoading', 'getFilteredOrdersbyON', 'getFilteredOrdersbyCN', 'getTotalItemsForOrders', 'getFilteredOrdersbyON', 'getFilteredOrdersbyCN', 'getArticleOrders', 'getAdminOrders', 'getFilteredOrdersByShipment']),
    clientsAll() {
      return this.allClients;
    },
    selectAllClients() {
      let clients
      if (this.isAllChecked == true) {
        clients = this.allClients
      } else {
        clients = this.selectedClients
      }
      return clients
    },
  },
  watch: {
    page(value) {
      if (this.searchCN != null) {
        this.adminOrdersOverview({
          salesType: 'Product',
          pageNumber: value,
          pageSize: this.pageSize,
          orderNumber: null,
          clientId: this.clientId,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderState: null,
        })
      } else if (this.searchOrder != null) {
        this.adminOrdersOverview({
          salesType: 'Product',
          pageNumber: value,
          pageSize: this.pageSize,
          orderNumber: this.searchOrder,
          clientId: null,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderState: null,
        })
      } else {
        this.adminOrdersOverview({
          salesType: 'Product',
          pageNumber: value,
          pageSize: this.pageSize,
          orderNumber: null,
          clientId: null,
          orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
          orderState: null,
        })
      }
    },
    searchOrder(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.noSearch = false
          this.removeLists()
          this.adminOrdersOverview({
            salesType: 'Product',
            pageNumber: this.page,
            pageSize: this.pageSize,
            orderNumber: null,
            clientId: null,
            orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
            orderState: null,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.searchByOrderNumber({ orderNumber: value, salesType: 'Product' });
          this.noSearch = true
        }
      }, 500);
    },
    searchCN(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.clientId = null
          this.noSearchTwo = false
          this.adminOrdersOverview({
            salesType: 'Product',
            pageNumber: this.page,
            pageSize: this.pageSize,
            orderNumber: null,
            clientId: null,
            orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
            orderState: null,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.searchByCustomerName(value);
          this.noSearchTwo = true
        }
      }, 500);
    },
    searchShip(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.adminOrdersOverview({
            salesType: 'Product',
            pageNumber: this.page,
            pageSize: this.pageSize,
            orderNumber: null,
            clientId: null,
            orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
            orderState: null,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.searchByShipmentCode(value);
          this.noSearchThree = true
        }
      }, 500);
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['getFileByOrderNumber', 'editOrderDateCreated', 'loadCreatedOredersPDF', 'loadCreatedOreders', 'loadCreatedOrdersByOrderNumber', 'changeLoadingtoTrue', 'resetSearchInNewOrders', 'searchInNewOrders', 'orderByOrderNumber', 'editOrders', 'loadOrderTypes', 'firstNames', 'getOrdersOverview', 'searchByCustomerName', 'searchByOrderNumber', 'readOrderItems', 'adminOrdersOverview', 'searchByShipmentCode']),
    changeOrderDate(value) {
      this.editOrderDateCreated({
        object: value,
        successCallback: () => {
          this.selectedClients = []
          this.isAllChecked = false
          this.allClients = []
          this.adminOrdersOverview({
            salesType: 'Product',
            pageNumber: this.page,
            pageSize: this.pageSize,
            orderNumber: null,
            clientId: null,
            orderType: null,
            orderState: null,
          })
        },
      })
    },
    toggleAllCheckboxes() {
      this.isAllChecked = !this.isAllChecked;

      if (this.isAllChecked) {
        this.fetchAllClients().then(() => {
          this.selectedClients = [...this.allClients];
        });
      } else {
        this.selectedClients = [];
      }
    },
    async fetchAllClients() {
      this.allClients = [];
      const promises = [];
      promises.push(this.getClientsOnPage(1));

      const totalPages = this.getCurrentPageForOrders;

      for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
        promises.push(this.getClientsOnPage(pageNumber));
      }
      await Promise.all(promises);
    },
    async getClientsOnPage(pageNumber) {
      await this.adminOrdersOverview({
        salesType: 'Product',
        pageNumber,
        pageSize: this.pageSize2,
        orderNumber: null,
        clientId: null,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderState: null,
      })
      this.allClients = [...this.allClients, ...this.getAdminOrders];
    },
    singleCheckbox(item) {
      const index = this.selectedClients.findIndex((selectedClient) => { return selectedClient.orderId === item.orderId });

      if (index !== -1) {
        this.selectedClients.splice(index, 1);
      } else {
        this.selectedClients.push(item);
      }
      if (this.selectedClients.length === 0 && this.allClients.length === 0) {
        this.isAllChecked = false;
      } else {
        this.isAllChecked = this.selectedClients.length === this.allClients.length;
      }
    },
    async searchByOn(value) {
      this.searchOrder = value
      this.noSearch = false
      await this.adminOrdersOverview({
        salesType: 'Product',
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: this.searchOrder,
        clientId: null,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderState: null,
      })
    },
    async searchByCn(value) {
      this.searchCN = value.nameToDisplay
      this.clientId = value.clientId
      this.noSearchTwo = false
      await this.adminOrdersOverview({
        salesType: 'Product',
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: null,
        clientId: value.clientId,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderState: null,
      })
    },
    async searchByShip(value) {
      this.searchShip = value.notes
      this.notes = value.notes
      this.noSearchThree = false
      await this.adminOrdersOverview({
        salesType: 'Product',
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: null,
        clientId: value.clientId,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderState: null,
        orderId: value.orderId,
      })
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },

    async loadMore() {
      if ((this.$refs.matsListing.offsetHeight + this.$refs.matsListing.scrollTop) + 2 >= this.$refs.matsListing.scrollHeight) {
        await this.fetchCreatedOrders(this.rows.currentPage + 1)
      }
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchInNewOrders();
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrdersbyON.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    dateFormat(value) {
      return moment(value).format('DD-MM-YYYY, HH:mm')
    },
    onArrowDown1(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrdersbyCN.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp1(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    onArrowDown3(ev) {
      ev.preventDefault()
      if (this.arrowCounter3 < this.getFilteredOrdersByShipment.length - 1) {
        this.arrowCounter3 += 1;
        this.fixScrolling();
      }
    },

    onArrowUp3(ev) {
      ev.preventDefault()
      if (this.arrowCounter3 > 0) {
        this.arrowCounter3 -= 1;
        this.fixScrolling()
      }
    },
    inputChanged3(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByShip(this.getFilteredOrdersByShipment[this.arrowCounter3])
        this.removeLists()
        this.arrowCounter3 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    inputChanged1(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getFilteredOrdersbyCN[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    showCustomerName() {
      this.toggleSarch = 'CustomerName';
      this.searchCN = '';
      this.show = true
      this.adminOrdersOverview({
        salesType: 'Product',
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: null,
        clientId: null,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderState: null,
      })
    },
    showOrderNumber() {
      this.toggleSarch = 'OrderNumber';
      this.searchOrder = '';
      this.show = false;
      this.adminOrdersOverview({
        pageNumber: this.page,
        salesType: 'Product',
        pageSize: this.pageSize,
        orderNumber: null,
        clientId: null,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderState: null,
      })
    },
    showShippingNotes() {
      this.toggleSarch = 'ShippingNotes';
      this.searchShip = ''
      this.show = false
      this.adminOrdersOverview({
        pageNumber: this.page,
        salesType: 'Product',
        pageSize: this.pageSize,
        orderNumber: null,
        clientId: null,
        orderType: this.selectedOrderType == null ? null : this.selectedOrderType,
        orderState: null,
      })
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredOrdersbyON[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
  },
}
</script>

<style scoped lang="scss">
.editButton {
  height: 30px;
}

.team_table td {
  padding: 16px 9px !important;
}

.active2 {
  font-weight: bold;
  color: $base-color;
}
.active2{
  color: $base-color;
  font-weight: bold;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  .mats-listing{
    margin-top: 0
  }
}
  .button {
    margin-bottom: 15px!important;
  }
  .mat {
    display: flex;
    justify-content: space-between;
    align-content: center;
    &.active {
      background-color: #e8e8e8;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    span {
      margin-left: 10px;
      svg {
        font-size: 15px;
      }
    }
  }

tr:last-child {
  border-bottom: none !important;
}

table:hover {
  cursor: pointer;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
</style>
